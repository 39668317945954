import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Header } from '@components';
import BetaBanner from '../BetaBanner';
import { headerZIndex } from '../../constants/styleConfigs';
import { accountTypes } from '../../constants/accountTypes';
import { useUser } from '@context/UserContext';

const Layout = ({ children }) => {
  const { user, fetchUser, isLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.account_type === accountTypes.DEVICE) {
      navigate('/device');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account_type]);

  return (
    <Box position="relative">
      <Box position="fixed" zIndex={headerZIndex} width="100%">
        <BetaBanner />
        <Header
          name={user?.username}
          image={user?.profile_image}
          hasLoading={isLoading}
          background="#0f0f0f"
          justifyContent="justify-dashboard-header"
        />
      </Box>
      <main style={{ paddingTop: '13.8rem' }}>{children}</main>
    </Box>
  );
};

export default Layout;
