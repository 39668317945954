import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Avatar,
} from '@mui/material';
import { format } from 'date-fns';

import { truncateText } from '@utils';

const columnHelper = createColumnHelper();
const placeholderImage = '/no-cover-image.png';

const columns = [
  columnHelper.accessor('time_recorded', {
    cell: (info) => {
      return (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            maxWidth: `${250}px`,
          }}
        >
          {format(new Date(info.getValue()), 'dd/MM/yy HH:mm:ss')}
        </Box>
      );
    },
    header: 'Time',
  }),
  columnHelper.accessor('cover_image', {
    cell: (info) => (
      <Avatar
        variant="rounded"
        src={
          info.getValue() && info.getValue() !== '-'
            ? info.getValue()
            : placeholderImage
        }
        alt="Cover"
        onError={(event) => (event.target.src = placeholderImage)}
      />
    ),
    header: '',
  }),
  columnHelper.accessor('title', {
    cell: (info) => {
      const text = info.getValue();
      const maxWidth = 364; // Set the max width in pixels or characters

      const maxChars = 50;

      return (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: `${maxWidth}px`,
          }}
        >
          {truncateText(text, maxChars)}
        </Box>
      );
    },
    header: 'Title',
  }),
  columnHelper.accessor('artist', {
    cell: (info) => info.getValue(),
    header: 'Artist',
  }),
  columnHelper.accessor('duration', {
    cell: (info) => {
      const durationMs = info.getValue();
      const minutes = Math.floor(durationMs / 60000);
      const seconds = Math.floor((durationMs % 60000) / 1000);
      if (minutes === 0) {
        return `${seconds}s`;
      }
      return `${minutes}m ${seconds}s`;
    },
    header: 'Duration',
  }),
  columnHelper.accessor('genres', {
    cell: (info) => info.getValue().join(', '),
    header: 'Genres',
  }),
  columnHelper.accessor('isrc', {
    cell: (info) => info.getValue(),
    header: 'ISRC',
  }),
];

const TrackList = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableCell
                key={header.id}
                sx={{
                  paddingLeft: index === 0 ? '6rem' : 'default',
                  paddingRight:
                    index === table.getHeaderGroups().length - 1
                      ? '6rem'
                      : 'default',
                  py: '0.5rem',
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <Box borderRadius={'0.8rem'} py="5rem" bgcolor={'background.section'}>
      <TableContainer>
        <Table sx={{ height: '100%' }}>
          {renderHeader()}

          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} style={{ border: 'none' }} hover>
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      paddingLeft: index === 0 ? '6rem' : 'default',
                      paddingRight:
                        index === row.getVisibleCells().length - 1
                          ? '6rem'
                          : 'default',
                      py: '0.5rem',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TrackList;
