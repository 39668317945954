import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Container, Grid, Button } from '@mui/material';

import { useDeviceDashboardData, exportEventsData } from '@apis';
import { useDetectOutsideClick } from '@hooks';
import { MIN_DATE_RANGE, DATA_ERRORS, FILTERS } from '@configs';
import {
  getDateDifference,
  getStartOfMonth,
  getFilenameFromContentDisposition,
  downloadBlob,
} from '@utils';
import { useUser } from '@context/UserContext';
import { LoadingLogo } from '@components';
import { SectionSkeleton } from '@components/Skeletons/SectionSkeleton';
import ServerErrorAlert from './components/v2/ServerErrorAlert';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopTracks from './components/v2/TopTracks';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';
import TopGenres from './components/v2/TopGenres';
import EventsList from './components/v2/EventsList';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';
import ExportEventsPromptWithLoader from './components/v2/ExportEventsPromptWithLoader';

const IS_REFRESH_DATE =
  localStorage.getItem('is_refresh') &&
  JSON.parse(localStorage.getItem('is_refresh'));

const IS_START_DATE =
  localStorage.getItem('start_date') && localStorage.getItem('start_date');
const IS_END_DATE =
  localStorage.getItem('end_date') && localStorage.getItem('end_date');

const INITIAL_DATE =
  (IS_REFRESH_DATE &&
    IS_START_DATE &&
    new Date(Number(localStorage.getItem('start_date')))) ||
  getDateDifference(3);

const END_DATE =
  (IS_REFRESH_DATE &&
    IS_END_DATE &&
    new Date(Number(localStorage.getItem('end_date')))) ||
  new Date();

export const DeviceDashboard = () => {
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [showExportPrompt, setShowExportPrompt] = useState(false);
  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);

  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);
  let { id } = useParams();
  const { user, isLoading: isLoadingUserInfo } = useUser();

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Last 3 months');

  const {
    fetchDashboardData,
    isLoadingDashboardData,
    data: dashboardData,
    serverError,
  } = useDeviceDashboardData();
  const { mutateAsync: exportEventsMutate, isPending: isExportEventsLoading } =
    useMutation({
      mutationFn: exportEventsData,
      onSuccess: (response) => {
        let contentType = response.headers?.['content-type'];
        let contentDisposition = response.headers?.['content-disposition'];

        let filename;
        if (contentType === 'text/csv; charset=utf-8') {
          filename =
            getFilenameFromContentDisposition(contentDisposition) ||
            'events.csv';
        } else if (contentType === 'application/zip') {
          filename = 'events.zip';
        } else {
          console.warn('Unexpected content type:', contentType);
          filename = 'downloaded_file'; // Fallback filename
        }

        // Create a blob with the correct content type
        const blob = new Blob([response.data], {
          type: contentType || 'application/octet-stream',
        });

        // Download the file
        downloadBlob(blob, filename);

        setShowExportPrompt(false);
      },
      onError: (error) => {
        console.error('Export failed:', error);
      },
    });

  const dashboard_response_status =
    dashboardData && dashboardData?.responseStatus;
  const dashboard_data = dashboardData && dashboardData?.data;

  useEffect(() => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (dashboardData) {
      if (!isLoadingDashboardData) {
        setIsLoadingDashboard(false);
      }
      getLastUpdateTime();
      if (id) {
        const breadcrumbDataWithDeviceName = [
          { key: 1, label: 'Home', href: '/' },
          { key: 2, label: dashboardData?.device_name },
        ];
        updateBreadcrumbs(breadcrumbDataWithDeviceName);
      } else {
        updateBreadcrumbs([{ key: 1, label: 'Home' }]);
      }
      if (DATA_ERRORS.includes(dashboardData.status)) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      }
      return;
    }

    if (serverError) {
      setIsLoadingDashboard(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_response_status, dashboard_data, serverError]);

  useEffect(() => {
    if (!user) {
      return;
    }

    getRefreshedTokenAndUpdateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange) {
      setDateRange(newDateRange);
    }
    if (isClickedOutside) {
      setIsShowCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  const getLastUpdateTime = () => {
    const d = new Date();
    const updateTime = `${d
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ')} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} `;
    setLastUpdateTime(updateTime);
  };

  const handleToggleCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  const getRefreshedTokenAndUpdateUser = () => {
    onAuthStateChanged(auth, async (authUser) => {
      setIsLoadingDashboard(true);

      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            user_id: user?.user_id,
            access_token: idToken,

            device_id: id,
            start_date: new Date(newDateRange[0])?.getTime(),
            end_date: new Date(newDateRange[1])?.getTime(),
          };
          fetchDashboardData(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleExportEvents = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,

            eventIds: selectedEventIds,
          };
          await exportEventsMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleSearchBtn = async () => {
    setIsShowCalendar(false);
    setNewDateRange(dateRange);
    localStorage.setItem('start_date', new Date(dateRange[0]).getTime());
    localStorage.setItem('end_date', new Date(dateRange[1]).getTime());
    setActiveFilter('');
  };

  const onChangeDateRange = (value) => {
    setDateRange(value);
  };

  const onClickDateFilters = (filter) => {
    let startDate = '';
    if (filter === 'This month') {
      startDate = getStartOfMonth();
    }

    if (filter === 'Last 3 months') {
      startDate = getDateDifference(3);
    }

    if (filter === 'Last 6 months') {
      startDate = getDateDifference(6);
    }

    if (filter === 'All Events') {
      startDate = 1704067200000;
    }
    setActiveFilter(filter);
    localStorage.setItem('start_date', new Date(startDate).getTime());
    localStorage.setItem('end_date', new Date().getTime());

    setDateRange([startDate, new Date()]);
    setNewDateRange([startDate, new Date()]);
  };

  const calendarProps = {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  };

  const filtersProps = {
    FILTERS,
    activeFilter,
    onClickDateFilters,
  };

  return (
    <>
      {(isLoadingDashboard || isLoadingUserInfo) && <LoadingLogo />}

      <Box pb="9rem">
        <Container maxWidth="xl">
          <DashboardHeader
            lastUpdateTime={lastUpdateTime}
            calendarProps={calendarProps}
            filtersProps={filtersProps}
            dashboardType={
              id ? dashboardTypes.deviceFromGroup : dashboardTypes.device
            }
            deviceName={dashboardData?.device_name}
            hasFilterOptions
            hasLoading={isLoadingDashboard}
          />
          {serverError ? (
            <Box mt="5.4rem" height="100%">
              <ServerErrorAlert message={serverError} />
            </Box>
          ) : (
            <>
              {' '}
              <Box mt={'54px'}>
                {isLoadingDashboard && <SectionSkeleton height="30rem" />}

                {dashboardData?.metrics.object && (
                  <>
                    <SectionHeading
                      headingText={dashboardData?.metrics.title}
                      tooltipText={dashboardData?.metrics.info}
                    />
                    <MetricCount
                      metrics={Object.entries(dashboardData?.metrics.object)
                        .filter(([key, _]) => key.startsWith('object'))
                        .map(([_, value]) => value)}
                      chartData={dashboardData?.metrics.object.chart_data}
                    />
                  </>
                )}
              </Box>
              <Box mt="5rem">
                <Grid container spacing={'4rem'}>
                  <Grid item xs={12} md={6}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {dashboardData?.section_1 && (
                      <>
                        <SectionHeading
                          headingText={dashboardData?.section_1.title}
                          tooltipText={dashboardData?.section_1.info}
                        />
                        <TopTracks tracks={dashboardData?.section_1.object} />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {dashboardData?.section_2 && (
                      <>
                        <SectionHeading
                          headingText={dashboardData?.section_2.title}
                          tooltipText={dashboardData?.section_2.info}
                        />

                        {dashboardData.section_2.object?.length === 0 ? (
                          <EmptyContent text="There are no genres in the list." />
                        ) : (
                          <TopGenres data={dashboardData?.section_2.object} />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box mt="5rem">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {!isLoadingDashboard && dashboardData?.section_3.object && (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <SectionHeading
                            headingText={dashboardData?.section_3.title}
                            tooltipText={dashboardData?.section_3.info}
                          />

                          <Box mb="2rem">
                            <Button
                              variant="outlined"
                              onClick={() => setShowExportPrompt(true)}
                              disabled={selectedEventIds.length === 0}
                            >
                              Export
                            </Button>
                          </Box>
                        </Box>
                        <EventsList
                          data={dashboardData?.section_3.object || []}
                          associatedDevice={{
                            deviceUrl: window.location.href,
                            deviceName: dashboardData?.device_name,
                          }}
                          onSelectionEventChange={(ids) => {
                            setSelectedEventIds(ids);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Box>

      <ExportEventsPromptWithLoader
        open={showExportPrompt}
        handleClose={() => setShowExportPrompt(false)}
        handleExport={handleExportEvents}
        loading={isExportEventsLoading}
        headingText="Export selected events"
      />
    </>
  );
};
