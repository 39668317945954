import React, { useState } from 'react';
import { Box, Avatar, Container } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../Modal';
import { Button } from '../Button';
import Logo from '@components/Logo';
import './index.css';

export const Header = ({
  name,
  image,
  background,
  justifyContent,
  hasLoading,
}) => {
  const authToken = localStorage.getItem('user_access_token');
  const userId = localStorage.getItem('user_id');
  const auth = getAuth();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      })
      .catch((err) => console.log('logout err: ', err));

    setOpenModal(false);
  };

  return (
    <>
      <Box bgcolor={background}>
        <Container maxWidth="xl">
          <Box className={`admin-header-wrapper ${justifyContent}`}>
            <Box
              className="admin-logo-wrapper"
              component={'a'}
              href="https://vollou.com/"
            >
              <Logo />
            </Box>

            {authToken && userId && (
              <Box>
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: 'none', md: 'flex' },
                    alignItems: 'center',
                  }}
                >
                  <Box className="header-avatar-wrapper">
                    <Avatar alt="" src={image} className="header-avatar" />
                    {hasLoading ? (
                      <Box width={90} height={10} bgcolor={'white'} />
                    ) : (
                      <p className="header-username">{name}</p>
                    )}
                  </Box>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenModal(true)}
                  >
                    Logout
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      <Modal
        open={openModal}
        onClick={handleLogout}
        onClose={onClose}
        isLogoutModal
        label="Logout"
        title="Are you sure you want to logout?"
      />
    </>
  );
};
