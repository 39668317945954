import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

const ExportEventsPromptWithLoader = ({
  open,
  handleClose,
  handleExport,
  loading,
  headingText,
}) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'unset';
    }

    // Cleanup on unmount
    return () => {
      document.documentElement.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      disableScrollLock
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width={'44rem'}
        bgcolor="background.section"
        border="none"
        borderRadius="8px"
        boxShadow={24}
        p={'5rem'}
        sx={{ transform: 'translate(-50%, -50%)', boxShadow: 'none' }}
        textAlign="center"
      >
        {loading && (
          <>
            <Box
              component="img"
              src="/logo–animated.gif"
              alt="Loading"
              sx={{ width: '120px', height: '120px' }}
            />
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              textAlign="center"
            >
              Exporting...
            </Typography>
          </>
        )}

        {!loading && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              textAlign="center"
            >
              {headingText}
            </Typography>
            <Box
              mt={'3.8rem'}
              display="flex"
              flexDirection="column"
              gap={'13px'}
            >
              <Button variant="outlined" size="large" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleExport} size="large" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Export'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ExportEventsPromptWithLoader;
